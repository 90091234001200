<template>
  <div id="app">
    <HomeScreen />
  </div>
</template>
<script>
import HomeScreen from "./components/HomeScreen.vue";

export default {
  title: "Cryptocurrency",
  name: "App",
  components: {
    HomeScreen,
  },
  data() {
    return {
      refreshInterval: null,
      refreshRate: 420000,
    };
  },
  mounted() {
    this.startAutoRefresh();
  },
  beforeDestroy() {
    this.stopAutoRefresh();
  },
  methods: {
    startAutoRefresh() {
      this.refreshInterval = setInterval(() => {
        location.reload();
      }, this.refreshRate);
    },
    stopAutoRefresh() {
      clearInterval(this.refreshInterval);
    },
  },
};

</script>

<style></style>
