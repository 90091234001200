var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data_currentlys[_vm.coin].show)?_c('div',{staticClass:"coin-item"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require(`@/assets/img/${
            _vm.data_currentlys[_vm.data_currentlys[_vm.coin].active_show].symbol_short
          }.svg`)}})]),_c('div',{staticClass:"name"},[_c('span',{staticClass:"symbol"},[_vm._v(_vm._s(_vm.data_currentlys[_vm.data_currentlys[_vm.coin].active_show].symbol_short))]),_c('span',{staticClass:"full"},[_vm._v(_vm._s(_vm.data_currentlys[_vm.data_currentlys[_vm.coin].active_show].symbol_desc))])])]),_c('div',{staticClass:"values"},[_c('div',{staticClass:"price"},[_c('span',{staticClass:"pair"}),_c('span',{staticClass:"live"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.data_currentlys[_vm.data_currentlys[_vm.coin].active_show].price))+" ("+_vm._s(_vm.data_currentlys[_vm.data_currentlys[_vm.coin].active_show].price_prefix)+") ")])]),_c('span',{staticClass:"change down",class:{
        item_percent_up:
          _vm.data_currentlys[_vm.data_currentlys[_vm.coin].active_show].diffrent24 >= 0,
        item_percent_down:
          _vm.data_currentlys[_vm.data_currentlys[_vm.coin].active_show].diffrent24 < 0,
      }},[_vm._v(_vm._s(_vm.data_currentlys[_vm.data_currentlys[_vm.coin].active_show].diffrent24)+"%")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }