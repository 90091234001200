import Vue from "vue";
import App from "./App.vue";
import VuePageTitle from "vue-page-title";
import "./assets/css/custom.css";

import { currency } from "@/currency";
Vue.filter("currency", currency);

import Velocity from "velocity-animate";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
Vue.component("velocity", Velocity);
Vue.use(VuePageTitle, {
  //prefix: 'My App - ',
  suffix: "- Stabit ",
});

new Vue({
  render: (h) => h(App),
}).$mount("#app");
